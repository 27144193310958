import { disableTiers, enableTiers } from '@/api/aqtiers';

const disableTier = {
	id: 'disableTier',
	selectionType: 'multiple',
	label: 'aqtiers.actions.disableTier',
	functionality: 'UPDATE_AQTIERS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqtiers.actions.disableTier');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqtiersid: registries[i].aqtiersid
			});
		}

		const data = await disableTiers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableTier = {
	id: 'enableTier',
	selectionType: 'multiple',
	label: 'aqtiers.actions.enableTier',
	functionality: 'UPDATE_AQTIERS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqtiers.actions.enableTier');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqtiersid: registries[i].aqtiersid
			});
		}

		const data = await enableTiers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableTier, enableTier]
};
